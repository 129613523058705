.testimonials__container {
  width: 50%;
  padding-bottom: 3rem !important;
}

.client__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 1rem;
  border: 0.2rem solid var(--color-primary);
}

.testimonial {
  background: var(--color-primary-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 1rem;
}

.swiper-pagination-bullet {
  background: white !important;
}

.swiper-pagination-bullet-active {
  background: var(--color-primary) !important;
}

/* ========== Media Queries (medium devices) ======== */

@media screen and (max-width: 1024px) {
  .testimonials__container {
    width: 60%;
  }
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {
  .testimonials__container {
    width: 90%;
  }

  .client__review {
    width: 90%;
  }
}
