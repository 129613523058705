.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.service {
  background: var(--color-bg-black);
  border-radius: 2rem;
  border: 1px solid transparent;
  height: fit-content;
  transition: var(--transition);
  margin: 0 auto;
}

.service:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 2rem 2rem 0 0;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1)
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}

/* ========== Media Queries (medium devices) ======== */

@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .service {
    height: auto;
  }
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .service {
    width: 90%;
  }
}
