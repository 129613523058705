header {
  height: 100vh;
  overflow: hidden;
}

.header__container {
  text-align: center;
  margin-top: 5rem;
  height: 100%;
  width: 100%;
  position: relative;
  /* background:
  linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(41, 0, 67, 0.7)
  ), url(../../assets/background-image-raindrops-neon.jpeg); */
}

/* ========= CTA ========= */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ========= Header Socials ========= */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 5vw;
  bottom: calc(50% - 53.5px);
}

.video__container {
  text-align: center;
}

#video {
  width: 20rem;
  height: 20rem;
  position: absolute;
  left: calc(50% - 10rem);
  object-fit: cover;
  border-radius: 50%;
  /* transform: translateX(-50%); */
  margin-top: 4rem;
}

/* ========= Scroll Down ========= */

.scroll__down {
  position: absolute;
  right: 5vw;
  bottom: calc(50% - 41px);
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ========== Media Queries (medium devices) ======== */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials, .scroll__down {
    display: none;
  }
}
