.contact__container {
  display: grid;
  grid-template-columns: 42% 52%;
  gap: 6%;
  align-items: center;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
}

.contact__option {
  background: var(--color-bg-variant);
  width: 70%;
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
}

/* ========= FORM ========== */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
}

input, textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: .5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: white;
}

/* ========== Media Queries (medium devices) ======== */

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {
  .contact__container {
    width: 100%;
  }

  .contact__option {
    width: 90%;
  }
}
