footer {
  background: var(--color-bg-black);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: white;
}

.footer__logo {
  width: 10%;
  aspect-ratio: 1/1;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;;
}

.footer__socials a {
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 50%;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.footer__copyright {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.footer__copyright-logo {
  color: var(--color-primary);
  font-size: 1rem;
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  .footer__socials {
    margin-bottom: 2rem;
  }

  .footer__copyright {
    margin-bottom: 5rem;
  }

  .footer__logo {
    width: 40%;
  }
}
