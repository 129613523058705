.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  background: var(--color-bg-black);
}

.portfolio__item:hover {
  transform: scale(1.03);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  height: 48%;
  object-fit: contain;
}

.portfolio__item-image img {
  height: 100%;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
}

/* ========== Media Queries (medium devices) ======== */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
