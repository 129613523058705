@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none; /* removes bullet */
  text-decoration: none; /* removes underline */
}

:root { /* css variables */
  --color-bg: #1f1f38;
  --color-bg-black: rgb(24, 24, 24);
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100vh;
}

::-webkit-scrollbar { /* hide scrollbar on right of the page */
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: black;
  color: var(--color-white);
  margin: 0 5vw;
  line-height: 1.7;
  /* background:
    linear-gradient(
      rgba(0, 0, 0, 0.0),
      rgba(41, 0, 67, 0.8)
    ), url(./assets/background-image-neon-bars-curved.jpeg); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

/* ================= General Styles =============== */

h1, h2, h3, h4 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ========== Media Queries (medium devices) ======== */

@media screen and (max-width: 1024px) {

  section {
    margin-top: 6rem;
  }
}

/* ========== Media Queries (small devices) ======== */

@media screen and (max-width: 600px) {

  section > h2 {
    margin-top: 2rem;
  }
}
